(function($) {
	'use strict';

	$('.sort-title').on('click', function() {
		$(this).next('.sort-prices').slideToggle(300);
		$(this).toggleClass('open');
	});

	$('.sorting-mobile').on('click', function() {
		$(this).next('.filters').slideToggle(300);
		$(this).toggleClass('open');
	});
})(jQuery);
